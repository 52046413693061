
import { defineStore } from 'pinia'
import { whczcRequest, request } from '@/utils/Tool'
// const system = import.meta.env.VITE_APP_PROJECT
// let prefix = 'whczc'
// if (system == 'whczc') prefix = 'whczc'
// else if (system == 'SGDW') prefix = 'CSKY'
const userkey = import.meta.env.VITE_APP_OUTPUTPATH + '_userBean'
export const useUser = defineStore('user', {
    // state是一个函数，返回一个对象
    state: () => {
        return {
            userBean: null, // 尽量避免直接修改此参数
            userResult: null,
            time: null // 记录存储时间
        }
    },
    persist: {
        enabled: true, // true 表示开启持久化保存
        strategies: [
            {
                key: userkey,
                storage: localStorage, // 可以选择对应的存储形式（localStorage或者sessionStroage）
                paths: ['userBean', 'time'] // 需要存储的内容
            }
        ]
    },
    getters: {
        getuserBean() {
            if (this.time) {
                const nowTime = new Date().getTime()
                if (nowTime - this.time > 3600 * 1000) { // 无操作10分钟过期
                    localStorage.removeItem(userkey)// 删除
                    return null
                } else {
                    this.time = nowTime
                    return this.userBean
                }
            } else {
                localStorage.removeItem(userkey)// 删除
                return null
            }
        }
    },
    actions: {

        setUserSession() {
            const curTime = new Date().getTime()
            this.time = curTime
        },
        async login(userInfo) {
            console.log(100)
            const { username, password } = userInfo
            const res1 = await whczcRequest('wuhan_Login', { bean: { username: username, password: password }}).then((res) => {
                if (res) {
                    this.userResult = res
                    return res
                }
            })
            if (res1) {
                const res2 = await whczcRequest('wuhan_getUserBean', null).then((result) => {
                    return result
                })
                if (res2) {
                    await whczcRequest('wuhan_getPersonInfo', { bean: { 'cardno': res2.decardno, 'cardtype': res2.cardtype, 'id': res2.id }}).then((res3) => {
                        const info = res3
                        this.dealUser(info)
                        this.userBean = info
                        this.setUserSession()
                    }).catch(() => {})
                }
            }
            return this.userResult
        },
        async getUser() {
            const res1 = await whczcRequest('wuhan_getUserBean').then((result) => {
                if (result) {
                    return result
                } else {
                    this.userBean = null
                    this.time = null
                    localStorage.removeItem(userkey)// 删除
                }
            })
            if (res1) {
                await whczcRequest('wuhan_getPersonInfo', { bean: { 'cardno': res1.decardno, 'cardtype': res1.cardtype, 'id': res1.id }}).then((res) => {
                    this.dealUser(res)
                    this.userBean = res
                    this.setUserSession(res)
                }).catch(() => {})
            }

            return this.userBean
        },
        async logout() {
            await whczcRequest('LTPU_logout').then(() => {
                this.userBean = null
                this.time = null
                localStorage.removeItem(userkey)// 删除
            })
        },
        dealUser(response) {
            if (response) {
                if (response.personname) {
                    response.showname = response.personname
                } else {
                    response.showname = response.loginusername
                }
                if (!response.facenum) { response.facenum = 0 }
            }
        }
    }
})

export const useUser_2 = defineStore('user_2', {
    // state是一个函数，返回一个对象
    state: () => {
        return {
            userBean: null, // 尽量避免直接修改此参数
            userResult: null,
            time: null // 记录存储时间
        }
    },
    persist: {
        enabled: true, // true 表示开启持久化保存
        strategies: [
            {
                key: userkey,
                storage: localStorage, // 可以选择对应的存储形式（localStorage或者sessionStroage）
                paths: ['userBean', 'time'] // 需要存储的内容
            }
        ]
    },
    getters: {
        getuserBean() {
            if (this.time) {
                const nowTime = new Date().getTime()
                if (nowTime - this.time > 3600 * 1000) { // 无操作60分钟过期
                    localStorage.removeItem(userkey)// 删除
                    return null
                } else {
                    this.time = nowTime
                    return this.userBean
                }
            } else {
                localStorage.removeItem(userkey)// 删除
                return null
            }
        }
    },
    actions: {
        setUserSession() {
            const curTime = new Date().getTime()
            this.time = curTime
        },
        renewUserBean() {
            if (this.time) {
                const nowTime = new Date().getTime()
                if (nowTime - this.time > 3600 * 1000) { // 无操作60分钟过期
                    this.userBean = null
                    this.time = null
                    // localStorage.removeItem(userkey)// 删除
                } else {
                    this.time = nowTime
                }
            } else {
                // this.userBean = null
                localStorage.removeItem(userkey)
            }
        },
        async login(userInfo) {
            console.log(200)
            const { username, password, persontype } = userInfo
            const res1 = await request('CSKY_login', { username: username, password: password, persontype: persontype }).then((res) => {
                if (res) {
                    this.userResult = res
                    return res
                }
            })
            if (res1) {
                const res2 = await request('CSKY_getUserBean', null).then((result) => {
                    return result
                })
                if (res2) {
                    await request('CSKY_getPersonInfo', { 'cardno': res2.cardno, 'cardtype': res2.cardtype, 'id': res2.id }).then((res3) => {
                        const info = res3
                        this.dealUser(info)
                        this.userBean = info
                        this.setUserSession()
                    }).catch(() => {})
                }
            }
            return this.userResult
        },
        async getUser() {
            const res1 = await request('CSKY_getUserBean').then((result) => {
                if (result) {
                    return result
                } else {
                    this.userBean = null
                    this.time = null
                    localStorage.removeItem(userkey)// 删除
                }
            })
            if (res1) {
                await request('CSKY_getPersonInfo', { 'cardno': res1.cardno, 'cardtype': res1.cardtype, 'id': res1.id, 'isSearchCodeValue': true }).then((res) => {
                    this.dealUser(res)
                    this.userBean = res
                    this.setUserSession(res)
                }).catch(() => {})
            }

            return this.userBean
        },
        async logout() {
            await request('CSKY_logout').then(() => {
                this.userBean = null
                this.time = null
                localStorage.removeItem(userkey)// 删除
            })
        },
        dealUser(response) {
            if (response) {
                if (response.personname) {
                    response.showname = response.personname
                } else {
                    response.showname = response.loginusername
                }
                if (response.phonenum) { // 忘记密码等通用模块参数处理
                    response.phone = response.phonenum
                }
            }
        }
    }
})

export const useUser_tjsw = defineStore('user_tjsw', {
    // state是一个函数，返回一个对象
    state: () => {
        return {
            userBean: null, // 尽量避免直接修改此参数
            userResult: null,
            time: null // 记录存储时间
        }
    },
    persist: {
        enabled: true, // true 表示开启持久化保存
        strategies: [
            {
                key: userkey,
                storage: localStorage, // 可以选择对应的存储形式（localStorage或者sessionStroage）
                paths: ['userBean', 'time'] // 需要存储的内容
            }
        ]
    },
    getters: {
        getuserBean() {
            if (this.time) {
                const nowTime = new Date().getTime()
                if (nowTime - this.time > 3600 * 1000) { // 无操作60分钟过期
                    localStorage.removeItem(userkey)// 删除
                    this.userBean = null
                    return null
                } else {
                    this.time = nowTime
                    return this.userBean
                }
            } else {
                this.userBean = null
                localStorage.removeItem(userkey)// 删除
                return null
            }
        }
    },
    actions: {
        setUserSession() {
            const curTime = new Date().getTime()
            this.time = curTime
        },
        renewUserBean() {
            if (this.time) {
                const nowTime = new Date().getTime()
                if (nowTime - this.time > 3600 * 1000) { // 无操作60分钟过期
                    this.userBean = null
                    this.time = null
                    // localStorage.removeItem(userkey)// 删除
                } else {
                    this.time = nowTime
                }
            } else {
                // this.userBean = null
                localStorage.removeItem(userkey)
            }
        },
        async login(userInfo) {
            const { username, password } = userInfo
            const res1 = await request('TJSW_Login', { username: username, password: password }).then((res) => {
                console.log(res)
                if (res) {
                    this.userResult = res
                    return res
                }
            })
            if (res1) {
                await request('TJSW_getUserBean', null).then(result => {
                    const info = result
                    this.dealUser(info)
                    this.userBean = info
                    this.setUserSession()
                })
                // if (res2) {
                //     await request('TJSW_getPersonInfo', { 'cardno': res2.cardno, 'cardtype': res2.cardtype, 'id': res2.id }).then((res3) => {
                //         const info = res3
                //         this.dealUser(info)
                //         this.userBean = info
                //         this.setUserSession()
                //     }).catch(() => {})
                // }
            }
            return this.userResult
        },
        async getUser() {
            await request('TJSW_getUserBean', null).then((result) => {
                if (result) {
                    this.dealUser(result)
                    this.userBean = result
                    this.setUserSession(result)
                } else {
                    this.userBean = null
                    this.time = null
                    localStorage.removeItem(userkey)// 删除
                }
            })
            // if (res1) {
            //     await request('TJSW_getPersonInfo', { 'cardno': res1.cardno, 'cardtype': res1.cardtype, 'id': res1.id, 'isSearchCodeValue': true }).then((res) => {
            //         this.dealUser(res)
            //         this.userBean = res
            //         this.setUserSession(res)
            //     }).catch(() => {})
            // }

            return this.userBean
        },
        async logout() {
            await request('TJSW_logout').then(() => {
                this.userBean = null
                this.time = null
                localStorage.removeItem(userkey)// 删除
            })
        },
        dealUser(response) {
            if (response) {
                if (response.personname) {
                    response.showname = response.personname
                } else {
                    response.showname = response.loginusername
                }
                if (response.phonenum) { // 忘记密码等通用模块参数处理
                    response.phone = response.phonenum
                }
            }
        }
    }
})

