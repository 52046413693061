
const truncate = function(value, num, end) {
    if (!value) {
        return ''
    }
    value = String(value)
    if (isNaN(num)) {
        num = 30
    }
    end = typeof end === 'string' ? end : '...'
    return value.length > num ? value.slice(0, num - end.length) + end /* istanbul ignore else*/
        : value
}
const nullreplace = function(str, ruv) {
    str ||= ruv ??= '-'
    return str
}

const obfuscation = function(value) {
    if (!value) return ''
    value = value.toString()
    if (value.length <= 2) {
        return value.substring(0, 1) + '*'
    } else {
        return replacepos(value, 1, value.length - 1, '*')
    }
    function replacepos(text, start, end, replacetext) {
        for (let i = 0; i < end - 2; i++) {
            replacetext += '*'
        }
        return text.substring(0, start) + replacetext + text.substring(end)
    }
}

const subTime16 = function(value) {
    if (!value) {
        return ''
    }
    value = String(value)
    const len = value.length
    return len > 16 ? value.slice(0, 16) : value.slice(0, len)
}

const subTime10 = function(value) {
    if (!value) {
        return ''
    }
    value = String(value)
    const len = value.length
    return len > 10 ? value.slice(0, 10) : value.slice(0, len)
}

// const plaintext = function (value) {
//     console.log(value)
//     if(value != null || value != ''){
//         const reg = /[\u4e00-\u9fa5]/g
//         let names = value.match(reg)
//         console.log(names)
//         value = names.join('')
//         return value
//     }else{
//         return ''
//     }
// }

export { truncate, nullreplace, obfuscation, subTime16, subTime10 }
