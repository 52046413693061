import { reactive, shallowReactive } from 'vue'
// 引入全部图标
// import * as Icons from "@element-plus/icons"

// 按需引入图标
import {
    UserFilled,
    Tools,
    Menu,
    Avatar,
    Management,
    List,
    Share,
    SuccessFilled,
    WarningFilled,
    CircleClose,
    CircleCloseFilled,
    CirclePlusFilled,
    HomeFilled,
    ArrowDown,
    UploadFilled,
    Briefcase,
    Finished,
    Edit,
    Tickets,
    ArrowRightBold,
    ArrowDownBold,
    QuestionFilled,
    View,
    Hide,
    Filter,
    Search,
    CaretBottom,
    RefreshRight,
    Right,
    Close,
    Checked,
    Connection,
    DocumentCopy,
    Select,
    Picture
} from '@element-plus/icons-vue'

const dictIcon = shallowReactive({
    'UserFilled': UserFilled,
    'Tools': Tools,
    'Menu': Menu,
    'Avatar': Avatar,
    'Management': Management,
    'List': List,
    'Share': Share,
    'SuccessFilled': SuccessFilled,
    'WarningFilled': WarningFilled,
    'CircleClose': CircleClose,
    'CircleCloseFilled': CircleCloseFilled,
    'CirclePlusFilled': CirclePlusFilled,
    'HomeFilled': HomeFilled,
    'ArrowDown': ArrowDown,
    'UploadFilled': UploadFilled,
    'Briefcase': Briefcase,
    'Finished': Finished,
    'Edit': Edit,
    'Tickets': Tickets,
    'ArrowRightBold': ArrowRightBold,
    'ArrowDownBold': ArrowDownBold,
    'QuestionFilled': QuestionFilled,
    'Hide': Hide,
    'View': View,
    'Filter': Filter,
    'Search': Search,
    'CaretBottom': CaretBottom,
    'RefreshRight': RefreshRight,
    'Right': Right,
    'Close': Close,
    'Checked': Checked,
    'Connection': Connection,
    'DocumentCopy': DocumentCopy,
    'Select': Select,
    'Picture': Picture
})

const installIcon = (app) => {
    // 便于模板获取
    app.config.globalProperties.$icon = dictIcon
}

export default installIcon
