import { CryptoJS } from '../assets/lib/n-crypto-js'
import { alertMsg } from '@/hooks/globle'
import $ from 'jquery'

export async function request(name, data) {
    return new Promise(function(resolve, reject) {
        const sys = import.meta.env.VITE_APP_BASE_CONTEXT
        if (!(sys == 'WHTAXIExam')) {
            if (data) {
                data = { bean: data }
            } else {
                data = {}
            }
        }

        let sjd = {
            service: encrypt(name, 'taidtaxiexamrest'),
            version: encrypt('1.0.0', 'taidtaxiexamrest'),
            data: encrypt(JSON.stringify(data), 'taidtaxiexamrest')
        }

        if (sys == 'WHTAXIExam') {
            sjd = {
                service: encryptByDES(name, 'longrise211thsssdsaqqas'),
                version: encryptByDES('1.0.0', 'longrise211thsssdsaqqas'),
                data: encryptByDES(JSON.stringify(data), 'longrise211thsssdsaqqas')
            }
        }

        $.ajax({
            contentType: 'application/json',
            type: 'post',
            url: '/' + import.meta.env.VITE_APP_BASE_CONTEXT + (sys == 'WHTAXIExam' ? '/restservices/http/single/query' : '/restservices/http/singleRequestWeb/query'),
            data: JSON.stringify(sjd),
            dataType: 'json',
            success: function(backData) {
                const thing = backData
                if (thing) {
                    if (thing.result) {
                        if (sys == 'WHTAXIExam') {
                            resolve(thing.result.result)
                        } else {
                            resolve(thing.result)
                        }
                    } else {
                        resolve(null)
                    }
                } else {
                    reject(null)
                }
            }
        })
    })
}

export async function whczcRequest(name, data) {
    // 部分接口没有bean，暂时注释
    // if (data) {
    //     data = { bean: data }
    // } else {
    //     data = {}
    // }
    // console.log(data);
    return new Promise(function(resolve, reject) {
        const sjd = {
            service: encryptByDES(name, 'longrise211thsssdsaqqas'),
            version: encryptByDES('1.0.0', 'longrise211thsssdsaqqas'),
            data: encryptByDES(JSON.stringify(data), 'longrise211thsssdsaqqas')
        }
        $.ajax({
            contentType: 'application/json',
            type: 'post',
            url: '/' + import.meta.env.VITE_APP_BASE_CONTEXT + '/restservices/http/single/query',
            data: JSON.stringify(sjd),
            dataType: 'json',
            success: function(backData) {
                const thing = backData
                if (thing) {
                    if (thing.result) {
                        resolve(thing.result.result)
                    } else {
                        resolve(null)
                    }
                } else {
                    reject(null)
                }
            }
        })
    })
}

export function encryptByDES(message, key) {
    var keyHex = CryptoJS.enc.Utf8.parse(key)
    var encrypted = CryptoJS.DES.encrypt(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}
export function encrypt(message, key) {
    var keyHex = CryptoJS.enc.Utf8.parse(key)
    var encrypted = CryptoJS.DES.encryptexp(message, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}
export function decryptByDESqas(ciphertext, key) {
    key = key || 'longrise211thsssdsaqqas'
    var keyHex = CryptoJS.enc.Utf8.parse(key)
    var decrypted = CryptoJS.DES.decrypt({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)// parse
    }, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
}

export function cutout() {
    var url = window.location.href
    if (url.indexOf('http://127.0.0.1') > -1) {
        return 'https://wuhan.yxybb.com/' + import.meta.env.VITE_APP_BASE_CONTEXT + '/'
    } else {
        var arr = url.split('/')
        return arr[0] + '//' + arr[2] + '/' + import.meta.env.VITE_APP_BASE_CONTEXT + '/' // 环境变量 采用
    }
}

export async function getCodeValue(tablename, codeid) {
    // aghb
    const result = await request('LTPU_getCodeValue', { 'tablename': tablename, 'codeid': codeid })
    return result
}

export async function getCodeValues(tablename) {
    // whczc
    // const result = await request('LTPU_getCodeValues', { bean: { 'otn': tablename }})
    // return result

    // aghb
    const result = await request('LTPU_getCodeValues', { 'otn': tablename })
    return result
}

export function getCodeValuesList(data, callback) {
    request('getCodeValuesList', { codetypelist: data }).then((result) => {
        if (result) {
            callback(result)
        } else {
            callback(null)
        }
    })
}

// 排序
export function sortList(arr) {
    for (var i = 0; i < arr.length; i++) {
        for (var j = 0; j < arr.length; j++) {
            if (parseInt(arr[i].codeid) < parseInt(arr[j].codeid)) {
                var temp = arr[i]
                arr[i] = arr[j]
                arr[j] = temp
            }
        }
    }
    return arr
}

// 设置个人中心证件号验证状态的颜色
export function setCardnoState(bascData) {
    var checktype = bascData.policecheck
    if (!checktype) {
        bascData.checktypename = '待公安库验证'
        $('#verify').css('color', '#ff5400')
    } else {
        if (checktype == 3 || checktype == 8) {
            if (checktype == 3) {
                bascData.checktypename = '人工验证不通过'
            } else {
                bascData.checktypename = '公安库验证不通过'
            }
            $('#verify').css('color', 'red')
        }
        if (checktype == 7) {
            bascData.checktypename = '公安库验证通过'
            $('#verify').css('color', '#006400')
            $('#verifytips').html('您的证件信息验证通过').show()
        }
        if (checktype == 4) {
            bascData.checktypename = '人工验证通过'
            $('#verify').css('color', '#006400')
            $('#verifytips').html('您的证件信息验证通过').show()
        }
        if (checktype == 1 || checktype == 2) {
            $('#verify').css('color', '#ff5400')
            if (checktype == 1) {
                bascData.checktypename = '待公安库验证'
            } else if (checktype == 2) {
                bascData.checktypename = '需人工审核'
                $('#verifytips').html('您的证件信息需人工审核，请前往上传照片页面上传身份证正反面').show()
            }
        }
    }
}

// 清除缓存
export function clearCache() {
    sessionStorage.removeItem('persontype')
    sessionStorage.removeItem('signupid')
}

// 报名校验基本信息和扩展信息必填字段
export function cansignup(personinfo) {
    const linkList = {
        cardno: 'base',
        photo: 'base'

        // domicileareaid: 'extra',
        // expertise: 'extra',
        // areaid: 'extra',
        // email: 'extra',
        // territory: 'extra'
    }

    let msg = ''
    for (const k in linkList) {
        if (!personinfo[k]) {
            if (linkList[k] == 'base') {
                if (k == 'photo') {
                    msg = '请上传个人头像!'
                } else {
                    msg = '请绑定基本信息!'
                }
            }

            jumplink(msg, linkList[k])
            return false
        }
    }
    return true
}
function jumplink(msg, pagelink) {
    alertMsg(msg, () => {
        window.location.href = '#/person/' + pagelink
    })
}

// 验证码未从接口获取
export function changecode(node) {
    var selectChar = [2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']// 所有候选组成验证码的字符，当然也可以用中文的
    var elementChar = ['font', 'i', 'b']
    var code = ''
    var codeLength = 4// 验证码的长度
    var html = ''
    for (var i = 0; i < codeLength; i++) {
        var charIndex = Math.floor(Math.random() * 33)
        code += selectChar[charIndex]
        var r = parseInt(Math.random() * 155)
        var g = parseInt(Math.random() * 155)
        var b = parseInt(Math.random() * 155)
        var e = parseInt(Math.random() * 3)
        var s = parseInt(Math.random() * 10) + 18
        var color = 'rgb(' + r + ',' + g + ',' + b + ')'
        html += '<' + elementChar[e] + " style='font-size:" + s + 'px;color: ' + color + "'>" + selectChar[charIndex] + '</' + elementChar[e] + '>&nbsp;'
    }
    $('#' + node).html(html)
    return code
}

// 验证身份证号
export function checkCardNo(cardno) {
    var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
    if (regIdCard.test(cardno)) {
        if (cardno.length == 18) {
            var idCardWi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
            var idCardY = [1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2]
            var idCardWiSum = 0
            for (var i = 0; i < 17; i++) {
                idCardWiSum += cardno.substring(i, i + 1) * idCardWi[i]
            }
            var idCardMod = idCardWiSum % 11
            var idCardLast = cardno.substring(17)
            if (idCardMod == 2) {
                return idCardLast == 'X' || idCardLast == 'x'
            } else {
                // 用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
                return idCardLast == idCardY[idCardMod]
            }
        }
    } else {
        return false
    }
}

// 验证手机号
export function checkPhone(phoneno) {
    if (!trimall(phoneno)) {
        return false
    }
    var phonenoExp = /^(1[3,4,5,6,7,8,9])\d{9}$/
    //var telExp = /^(0[0-9]{2,3})?(-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
    return phonenoExp.test(phoneno)
}
export function checkTel(phoneno) {
    if (!trimall(phoneno)) {
        return false
    }
    var phonenoExp = /^(1[3,4,5,6,7,8,9])\d{9}$/
    var telExp = /^(0[0-9]{2,3})?(-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
    return phonenoExp.test(phoneno) || telExp.test(phoneno)
}
// 验证邮箱
export function checkEmail(email) {
    var emailExp = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
    if (emailExp.test(email)) {
        return true
    } else {
        return false
    }
}

// 去掉字符串中所有空格
export function trimall(str) {
    if (str && str !== '' && str != null && str !== undefined) {
        str = str.replace(/\s/g, '')
        if (str !== '') {
            return str
        } else {
            return null
        }
    } else {
        return null
    }
}

// 去掉html中所有标签
export function delLabel(s) {
    var s1 = s.replace(/<\/?.+?>/g, '')
    return s1.replace(/&nbsp;/g, '')
}

// 验证姓名中是否全为中文
export function isChn(str) {
    var reg = /^[\u4E00-\u9FA5]+$/
    var flag = false
    for (var i = 0; i < str.length; i++) {
        if (!flag) {
            flag = reg.test(str)
        }
    }
    return flag
}

export function judJSON(str) {
    if ((str.indexOf('[') == 0 || str.indexOf('{') == 0) && str.length > 10) {
        return true
    } else {
        return false
    }
}

/** 将全局 CSS 变量导入 JS 中使用 */
export const getCssVariableValue = (cssVariableName) => {
    let cssVariableValue = ''
    try {
        // 没有拿到值时，会返回空串
        cssVariableValue = getComputedStyle(document.documentElement).getPropertyValue(cssVariableName)
    } catch (error) {
        console.error(error)
    }
    return cssVariableValue
}
export const isExternal = (path) => {
    const reg = /^(https?:|mailto:|tel:)/
    return reg.test(path)
}

export function convertToChinaNum(num) {
    var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九')
    var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿')
    // 可继续追加更高位转换值
    if (!num || isNaN(num)) {
        return '零'
    }
    var english = num.toString().split('')
    var result = ''
    for (var i = 0; i < english.length; i++) {
        var des_i = english.length - 1 - i// 倒序排列设值

        result = arr2[i] + result

        var arr1_index = english[des_i]

        result = arr1[arr1_index] + result
    }
    // 将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')

    // 合并中间多个零为一个零
    result = result.replace(/零+/g, '零')

    // 将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')

    // 将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿')

    // 移除末尾的零
    result = result.replace(/零+$/, '')
    // 将【零一十】换成【零十】
    // result = result.replace(/零一十/g, '零十');貌似正规读法是零一十
    // 将【一十】换成【十】
    result = result.replace(/^一十/g, '十')
    return result
}

export function dateFormat(date) {
    var year = date.getFullYear()
    var month = (date.getMonth() + 1).toString()
    var day = (date.getDate()).toString()
    if (month.length == 1) {
        month = '0' + month
    }
    if (day.length == 1) {
        day = '0' + day
    }
    var hours = date.getHours().toString()
    if (hours.length == 1) {
        hours = '0' + hours
    }
    var mintus = date.getMinutes().toString()
    if (mintus.length == 1) {
        mintus = '0' + mintus
    }
    var second = date.getSeconds().toString()
    if (second.length == 1) {
        second = '0' + second
    }
    var dateTime = year + '-' + month + '-' + day + ' ' + hours + ':' + mintus + ':' + second
    return dateTime
}

// 学历专业代码拼接展示
export function pinjiemajorcode(backdata) {
    if (backdata) {
        for (var i = 0; i < backdata.length; i++) {
            if (backdata[i].majorcode) {
                var st = JSON.parse(backdata[i].majorcode)
                backdata[i].majorandcode = backdata[i].major + '(' + st.code + ')'
            } else {
                backdata[i].majorandcode = backdata[i].major
            }
        }
    }
    return backdata
}

// 移动端时间选择器iosselect
export function gettimeInfo(now) {
    if (!now) {
        now = new Date()
    } else {
        now = new Date(now)
    }
    var nowYear = now.getFullYear()
    var nowMonth = now.getMonth() + 1
    var nowDay = now.getDate()

    function yearData() {
        var years = []
        for (var i = 1960, len = 2100; i < len; i++) {
            if (i < 10) {
                i = '0' + i
            }
            years.push({
                id: i,
                value: i + '年'
            })
        }
        return years
    }
    const dateMonth = function(year, callback) {
        if (year == nowYear) {
            callback(monthData(12))
        } else {
            callback(monthData(12))
        }
    }
    function monthData(nowMonth) {
        var months = []
        for (var i = 1, len = 13; i < len; i++) {
            if (i < 10) {
                i = '0' + i
            }
            months.push({
                id: i,
                value: i + '月'
            })
        }
        return months
    }
    const dateDay = function(year, month, callback) {
        var showmonth = month * 1
        if (!showmonth) {
            showmonth = nowMonth * 1
        }
        if (/^(1|3|5|7|8|10|12)$/.test(showmonth)) {
            callback(dayData(31))
        } else if (/^(4|6|9|11)$/.test(showmonth)) {
            callback(dayData(30))
        } else if (/^2$/.test(showmonth)) {
            if (year % 4 === 0 && year % 100 !== 0) {
                callback(dayData(29))
            } else if (year % 400 === 0) {
                callback(dayData(29))
            } else {
                callback(dayData(28))
            }
        } else {
        }
    }
    function dayData(data) {
        var days = []
        for (var i = 1, len = data + 1; i < len; i++) {
            if (i < 10) {
                i = '0' + i
            }
            days.push({
                id: i,
                value: i + '日'
            })
        }
        return days
    }

    var obj = {
        list: [yearData(), dateMonth, dateDay],
        nowYear: nowYear,
        nowMonth: nowMonth,
        nowDay: nowDay
    }
    return obj
}

export function dealProve(examprove) {
    var data = JSON.parse(examprove)
    if (data) {
        var list = new Array()
        for (var i in data) {
            list.push({
                'filename': data[i].showName + (data[i].name).substring((data[i].name).indexOf('.')),
                'href': 'LEAP/Download/' + data[i].nameedPath + '/' + data[i].name,
                'fileid': data[i].name.substring(0, data[i].name.indexOf('.'))
            })
        }

        return { list: list, data: examprove }
    }
}

export const hasfosystem = () => {
    return import.meta.env.VITE_APP_BASE_CONTEXT
}

// 2024-01-05-whczc
// 验证手机号
export const checkPhoneno = (phoneno) => {
    if (!trimall(phoneno)) {
        return false
    }
    var phonenoExp = /^(1[3,4,5,6,7,8,9])\d{9}$/
    var telExp = /^(0[0-9]{2,3})?(-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
    return phonenoExp.test(phoneno) || telExp.test(phoneno)
}

// 未填写信息提示（专用于表单是否填写，手机端）
export const showTipsBox = (content) => {
    var $node = $('#tips-box')
    if (!$node || $node.length === 0) {
        var html = '<div class="js-tips-box" id="tips-box"><span></span></div>'
        $('body').append(html)
        $node = $('#tips-box')
    }
    $node.css({ 'display': 'block' })
    $node.children('span').text(content)

    setTimeout(function() {
        $node.css({ 'display': 'none' })
    }, 3000)
}

// 保存成功/错误提示（是否请求成功，手机端）
export const showToast = (icon, content) => { // icon必须是iconfont的字体文件
    var $node = $('#toast')
    if (!$node || $node.length === 0) {
        var html = '<div class="js-toast-box" id="toast"><div><i class="iconfont"></i><span></span></div></div>'
        $('body').append(html)
        $node = $('#toast')
    }
    $node.css({ 'display': 'block' })
    $node.find('span').text(content)
    $node.find('i').addClass(icon)

    setTimeout(function() {
        $node.css({ 'display': 'none' })
    }, 3000)
}

export const showSignChoose = (element) => {
    var inter = window.setInterval(function() {
        if ($('#' + element).length) {
            window.clearInterval(inter)
            $('#' + element).show()
            $('.showSignmask').show()
        }
    }, 200)
}

export const alertBox = (tip, cancelText, confirmText, callBack) => {
    if (!confirmText) {
        confirmText = '确定'
    }
    var node = $('.pop-dialog')
    var $buttons
    var display_cancel = cancelText ? 'block' : 'none'
    if (!node || node.length === 0) {
        var html = '<div class="pop-dialog">' +
            '<div class="pop-dialog-info">' +
            '<h2 class="fs-34 c-3">提示</h2>' +
            '<p class="pop-dialog-content">' + tip +
            '</p>' +
            '<div class="pop-dialog-footer flex-h">' +
            '<a href="javascript:;" style="display: ' + display_cancel + '" class="c-danger">' + cancelText + '</a>' +
            '<a href="javascript:;">' + confirmText + '</a>' +
            '</div></div></div>'
        $('body').append(html)
        node = $('.pop-dialog')
        $buttons = $('.pop-dialog-footer').find('a')
    } else {
        node.find('.pop-dialog-content').text(tip)
        $buttons = $('.pop-dialog-footer').find('a')
        $buttons.eq(0).text(cancelText).css('display', display_cancel)
        $buttons.eq(1).text(confirmText)
    }
    node.removeClass('dis-none')
    $buttons.eq(0).unbind('click')
    $buttons.eq(1).unbind('click')
    $buttons.eq(0).bind('click', function() {
        node.addClass('dis-none')
    })
    $buttons.eq(1).bind('click', function() {
        if (callBack) {
            callBack()
        }
        node.addClass('dis-none')
    })
}

// 获取url对应参数的值
export const getUrlVar = (name) => {
    var url = window.location.search // 获取url中"?"符后的字串
    var theRequest = {}
    if (url.indexOf('?') != -1) {
        var str = url.substr(1)
        var strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
            // encodeURI与decodeURI配合使用 但encodeURI编码与自动编码是一样的 可以不写
            theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1])
        }
    }
    return theRequest[name]
}
